import React, { useRef } from "react"
import Layout from "../../components/layout.js"
import SEO from "../../components/seo.js"
import styled from "styled-components"
import { navigate, Link } from "gatsby"
import { InPageNav } from "../../components/navbar.js"

import { McatHero } from "../../components/sections/heroSection.js"
import MCATPricing from "../../components/sections/pricingSection.js"
import Showcase from "../../components/sections/showcaseSection.js"
import {
  ValuePropLeft,
  ValuePropRight,
  ValuePropsX3,
} from "../../components/sections/valuePropSection.js"
import { TextSection } from "../../components/sections/sections.js"

//SVGs
import VideoSvg from "../../images/video_1.svg"
import QuestionSvg from "../../images/questions.svg"
import WorkSvg from "../../images/work.svg"
import Savings from "../../images/savings.svg"
import Unparalleled from "../../images/unparalleled.svg"
import Guarantee from "../../images/finish.svg"
import InterviewSVG from "../../images/interview.svg"

const card1 = {
  packageName: "",
  price: "",
  duration: "",
  description: "",
  onClick: "",
  offered: [
    "4+ Hour Days",
    "505+ Score Guarantee",
    "Be Fully Prepared In 4-6 Months",
    "90+ Hours Recorded Video Content",
    "Master-Focussed Approach",
    "7000+ Content Questions",
  ],
  notOffered: [],
}

const card2 = {
  packageName: "",
  price: "",
  duration: "",
  description: "",
  onClick: "",
  offered: [
    "8+ Hour Days",
    "510+ Score Guarantee",
    "Be Fully Prepared In 3-4 Months",
    "90+ Hours Recorded Video Content",
    "Master-Focussed Approach",
    "7000+ Content Questions",
  ],
  notOffered: [],
}
const card3 = {
  packageName: "",
  price: "",
  duration: "",
  description: "",
  onClick: "",
  offered: [
    "12+ Hour Days",
    "515+ Score Guarantee",
    "Be Fully Prepared In 1-2 Months",
    "90+ Hours Recorded Video Content",
    "Master-Focussed Approach",
    "7000+ Content Questions",
  ],
  notOffered: [],
}
const Span = styled.span`
  color: #f50057;
`
// const Sd = styled(SectionDescription)`
//   margin: 50px auto;
//   width: 90%;
//   font-size: 22px;
//   text-align: justify;
//   text-align-last: center;
// `
const InterviewPrep = ({ location }) => {
  const mediaRef = useRef(null)
  const RedirectToPage = dest => {
    navigate(dest)
  }

  return (
    <Layout>
      <SEO title="99point9 Intertview Preparation" />
      <InPageNav
        pageTitle="Interview Preparation"
        currentPath={location.pathname}
        navItems={[
          { link: "/interview_preparation", label: "Overview" },
          {
            link: "/interview_preparation/how_it_works",
            label: "How It Works",
          },
          { link: "/interview_preparation/schedule", label: "Class Schedule" },
          { link: "/interview_preparation/pricing", label: "Pricing" },
        ]}
        clickCallback={RedirectToPage}
      />
      <McatHero
        title="Interview Preparation"
        body="Interviewing is about being yourself, and you’re likely awesome! But it’s also about clearly conveying the person that you are to others. You already walk the walk—we’ll help you talk about walking."
        ctaPrimary={"Get Started"}
        ctaSecondary={"Hit us up first"}
        img={InterviewSVG}
        imgWidth="450px"
        backgroundColor="inherit"
      />

      <Showcase />
      <TextSection
        heading={"Pro-tip"}
        col1={[
          `While interviewing is absolutely about being yourself, and you’re
          likely`,
          <Span>*</Span>,
          `awesome, success in this regard also necessitates
          clearly, logically conveying the person you are to others. And that
          can take some skill-building.`,
        ]}
        col2={[
          <Span style={{ fontSize: "16px" }}>
            * You’re definitely awesome, don’t worry, haha--but because we
            haven’t met yet, we can’t definitively know that ;) <br /> <br />
            C’mon, you evidence-based-career prospect, you! Shoulda known
            better. If you’re still down to talk to me (Sophia) after this
            pre-emptive digggg,
            <b>
              <Link to="/contact"> click here</Link>
            </b>{" "}
            to hit us upppp!
          </Span>,
        ]}
      />

      <MCATPricing card1={card1} card2={card2} card3={card3} />
      <ValuePropsX3
        // caption = {"FOCUS ON YOUR WORK"}
        // refs={mcatBenefits}
        float={"center"}
        title={"What do you get?"}
        asides={[
          { title: "515+ Score Guarantee", src: Guarantee, alt: "Notes Image" },
          { title: "Affordable", src: Savings, alt: "Schedule Image" },
          {
            title: "Unparalleled Content",
            src: Unparalleled,
            alt: "Review Sheet Image",
          },
          { title: "92+ Hours Video", src: VideoSvg, alt: "Notes Image" },
          {
            title: "Online & In-class Options",
            src: WorkSvg,
            alt: "Schedule Image",
          },
          {
            title: "7000+ Lecture Questions",
            src: QuestionSvg,
            alt: "Review Sheet Image",
          },
        ]}
      />
      <ValuePropLeft
        caption={"BUILT-IN"}
        title={"Accountability"}
        refs={mediaRef}
      />

      <ValuePropRight caption={"AUTOMATED"} title={"Effortless Planning"} />

      <ValuePropLeft
        caption={"ARTIFICALLY INTELLIGENT"}
        title={"Content Personalization"}
      />

      <ValuePropRight
        caption={"HOLISTIC"}
        title={"Med. School Application Aid"}
      />

      <ValuePropLeft
        caption={"ARTIFICALLY INTELLIGENT"}
        title={"Content Personalization"}
      />
    </Layout>
  )
}

export default InterviewPrep
